var fvPlayer = {
	player: null,
	init: function(src, id){
		//var fvPlayer.player = null; // プレーヤー参照
		//console.log(src, id);
		var videoSrc = src;
		new FvLiveFmPlayer(id, {
			// UIの色調 (white/black) デフォルトはblack
			"theme": "black",
			// ソース映像URL
			"videoUrl": videoSrc,
			// ソース映像のサイズ
			"videoSize": { "width": 2560, "height": 2160 },
			// ソース映像の分割数(x,y)
			"videoSplit": { "x": 2, "y": 3 },
			// 横向レイアウト
			"canvasSize"        : { "width": 1920, "height": 648 },  // 描画領域サイズ
			"mainCanvasLayer"   : { "width": 1152, "height": 648, "top": 0, "left": 0, "title": "正面カメラ" },         // メインビュー配置
			"subCanvasLayer"    : [
					  { "width": 384, "height": 216, "top": 0,   "left": 1152, "title": "カメラ1", "tag":'camera1' }, // サブビュー配置
					  { "width": 384, "height": 216, "top": 0,   "left": 1536, "title": "カメラ2", "tag":'camera2' },
					  { "width": 384, "height": 216, "top": 216, "left": 1152, "title": "カメラ3", "tag":'camera3' },
					  { "width": 384, "height": 216, "top": 216, "left": 1536, "title": "カメラ4", "tag":'camera4' },
					  { "width": 384, "height": 216, "top": 432, "left": 1152, "title": "カメラ5", "tag":'camera5' },
					  { "width": 384, "height": 216, "top": 432, "left": 1536, "title": "カメラ6", "tag":'camera6' }
			],
			// モバイル端末縦向き時レイアウト
			"canvasSize2"      : { "width": 960, "height": 900 },
			"mainCanvasLayer2" : { "width": 960, "height": 540, "top": 0, "left": 0, "title": "正面カメラ" },
			"subCanvasLayer2"  : [
					{ "width": 320, "height": 180, "top": 540, "left": 0,   "title": "カメラ1", "tag":'camera1' },
					{ "width": 320, "height": 180, "top": 540, "left": 320, "title": "カメラ2", "tag":'camera2' },
					{ "width": 320, "height": 180, "top": 540, "left": 640, "title": "カメラ3", "tag":'camera3' },
					{ "width": 320, "height": 180, "top": 720, "left": 0,   "title": "カメラ4", "tag":'camera4' },
					{ "width": 320, "height": 180, "top": 720, "left": 320, "title": "カメラ5", "tag":'camera5' },
					{ "width": 320, "height": 180, "top": 720, "left": 640, "title": "カメラ6", "tag":'camera6' }
			],
			"isDebug": false,
			"isRetryOff": true,
			"isLive": true,      // true: ライブ配信時 false: VOD配信時
			"autoplay": false,    // true: 自動再生開始 false: 自動再生オフ
								  // Chromeではtrueを設定してもfalse設定時の挙動になります
			"enableLoop": true,   // VOD再生終了時のループ再生可否 
			"showLicensee": false,
			"disableSeek": false, // true: シークバーが表示されますがシーク不能となります。
			"fvpadding": 0,       // 固定値: 0を設定してください
			"enableShare": false, // true: SNSシェアボタンを表示します
			"contentID"     : "border2021",
			"gaTrackingId"  : "UA-58255679-37",
			"statsInterval" : 10,
			"key"           : "Q3MjNkZjI3ZT",
			"tracking"      : "https://gnzo-lb.gnzo.com/hlsauth/tracking.php",
			onReady: function(player) {
				console.log("onReady");
				fvPlayer.player = player;
			},
			// ビューの切替時
			onCameraSelect: function(tag, caption) {
				console.log("onCameraSelect tag="+tag+" caption="+caption);
			},
			// 再生開始
			onPlay: function() {
				console.log("onPlay");
			},
			// 再生停止
			onPause: function() {
				console.log("onPause");
			},
			onError: function() {
				console.log("onError");
			},
			// コンテンツ終端到達 (VODコンテンツのみ通知)
			onEOF: function() {
				console.log("onEOF");
			},
			// プレーヤーリサイズ時、プレーヤーサイズを通知
			onResize: function(width, height) {
				console.log("onResize width="+width+" height="+height);
			},
		});
		// HTML側処理例
		function seekPlayer(seek_to) {
			if(fvPlayer.player) {
				fvPlayer.player.setCurrentTime(seek_to);
			}
			return false;
		}
	}
}