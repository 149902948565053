var onlineMovie = {
	duration: 10000,
	init: function(){
		common.scrollToPosition(0, 100);
		$("#online").addClass("authorized");
		setTimeout(function(){
			//fvPlayerSample.player.pause();
			fvPlayer.init("https://d3oa0h9lb5tc72.cloudfront.net/livecf/fv1.smil/playlist.m3u8", "fvPlayer");
			setTimeout(function(){
				online.closeModal();
				onlineMovie.startPolling();
				window.scrollTo(0,0);
			}, 600);
		}, 100);
	},
	startPolling: function(){
		onlineMovie.checkToken();
		setTimeout(function(){
			onlineMovie.checkToken();
		}, onlineMovie.duration);
	},
	checkToken: function(){
		let url = apiURL + "/access_tokens/verify.json";
		let params = {
			"ticket_id": me.ticketID,
			"serial_code": me.serial,
			"access_token": me.token
		}
		let check = true;
		//console.log(params);
		$.ajax({
			type: "POST",
			dataType: 'json',
			url: url,
			data: params,
			success	: function(json){
				if(check){
					check = false;
					setTimeout(function(){
						onlineMovie.checkToken();
					}, onlineMovie.duration);
				}
			},
			error: function(XMLHttpRequest, textStatus, errorThrown){
				if(XMLHttpRequest.status === 422){
					$("#movie_error").addClass("active");
					fvPlayer.player.pause();
					setTimeout(function(){
						$("#webmovie_content").remove();
					}, 100);
				}
			}
		});
		function closeMovie(){
			$("#movie_error").addClass("active");
			fvPlayer.player.pause();
			setTimeout(function(){
				$("#webmovie_content").remove();
			}, 100);
		}
	}
}