var comment = {
	current: 0,
	max: 0,
	init: function(){
		comment.current = 0;
		comment.max = Math.ceil($(".cmt").length / 5);
		console.log(comment.current, comment.max);
		comment.change();
		comment.nav();
	},
	resize: function(){
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			$(".cmt").css("top", "");
			let arry = [0,0];
			$(".cmt").each(function(i){
				console.log(i, arry);
				let $this = $(".cmt").eq(i);
				if(i%2 === 0){
					//left
					$this.css({
						"top": arry[0] + "px",
						"display": "block"
					});
					arry[0] += $this.outerHeight() + 54;
				}else{
					$this.css({
						"top": arry[1] + "px",
						"display": "block"
					});
					arry[1] += $this.outerHeight() + 54;
				}
			});
			let _h = Math.max.apply(null,arry);
			$(".comments").css("height", _h + "px");
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			comment.change();
		}
	},
	nav: function(){
		$(".show_next").off().on("click", function(){
			$(".comments").addClass("disable");
			let posY = $("#block_comment").offset().top;
			common.scrollToPosition(posY, 400);
			setTimeout(function(){
				comment.current++;
				if(comment.current >= comment.max){
					comment.current = comment.max-1;
				}
				comment.change();
			}, 400);
		});	
		$(".show_prev").off().on("click", function(){
			$(".comments").addClass("disable");
			let posY = $("#block_comment").offset().top;
			common.scrollToPosition(posY, 400);
			setTimeout(function(){
				comment.current--;
				if(comment.current < 0){
					comment.current = 0;
				}
				comment.change();
			}, 400);
		});	
	},
	change: function(){
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			
		}else{
			let start = 5*comment.current;
			let end = start + 5;
			console.log(start, end)
			$(".cmt").css("display", "none");
			for(var i = start; i < end; i++){
				console.log(i)
				$(".cmt").eq(i).css({
					"display": "block"
				});
			}
			$(".nums .current").html(comment.current+1);
			if( comment.current === 0 ){
				$(".show_prev").addClass("disable");
				$(".show_next").removeClass("disable");
			}else if( comment.current >= comment.max ){
				$(".show_prev").removeClass("disable");
				$(".show_next").addClass("disable");
			}else{
				$(".show_prev").removeClass("disable");
				$(".show_next").removeClass("disable");
			}
			if( $(".comments").hasClass("disable") ){
				$(".comments").removeClass("disable");
			}
		}
	}
}