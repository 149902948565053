var isPage = "";
var Scrollbar, scrollbar;

let Animate = {
	top: 0,
	show: 0
}
let animations = [];
var common = {
	status: "",
	scrollTop: 0,
	ready: false,
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		home.init();
		if(isPage === "top" || isPage === "comment"){
			
		}else if(isPage === "goods"){
			goods.init();
			common.start();
		}else if(isPage === "online"){
			online.init();
			common.start();
		}else if(isPage === "live_ticket"){
			ticket.init();
		}else{
			common.start();
		}
		if(isPage === "comment"){
			comment.init();
		}
		common.resize();
		common.nav();
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	start: function(){
		setTimeout(function(){
			common.resize();
			$("body").removeClass("disable");
			if( $html.classList.contains("page") ){
				$("#kv").addClass("active");
				setTimeout(function(){
					$(".a").eq(0).addClass("animate")
				}, 600);
			}
		}, 600);
	},
	nav: ()=>{
		$(".pagetop a").off().on("click", function(){
			common.scrollToPosition(0, 1000);
		});
		$("a.scroll_to_attention").off().on("click", function(){
			let posY = 0;
			if(!isSP && !isIPad && !isIE11 && !isEdge){
				posY 	= Math.floor( $("#attention").offset().top ) + common.scrollTop;
			}else{
				posY 	= Math.floor( $("#attention").offset().top );
			}
			common.scrollToPosition(posY, 1000);
		});
		$(".open_modal_pay").off().on("click", function(){
			if( !$html.classList.contains("modal_pay") ){
				common.showModalPay();
			}else{
				common.closeModalPay();
			}
		});
		$("a#btn_menu").off().on("click", function(){
			if( !$html.classList.contains("menu") ){
				$html.classList.add("menu");
			}else{
				$html.classList.remove("menu");
			}
		});
		$(".lang li a").not(".active").off("click").on("click", function(){
			let lang = getLang();
			let path = location.pathname;
			if(lang === "jp"){
				console.log(path)
				path = "/en" + path;
				location.href = path;
			}else{
				//日本語サイトへ遷移
				path = path.replace("/en", "");
				location.href = path;
			}
		});
		//share
		let $this;
		$(".footer_bottom .sns li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".footer_bottom .sns li a").index(this);
			shareTxt(idx);
		});
		function shareTxt(num){
			let url = "https://border.dance";
			if(num === 0){
				//Twitter
				var txt = "border 2021 official website %0a";
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&text=" + txt,
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = "border 2021 official website \n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		if (window.matchMedia('all and (min-width : 769px)').matches) {
			isSmallScreen = false;
			$(".video video").attr("src", "/assets/images/top/top_pc.mp4");
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
			$(".video video").attr("src", "/assets/images/top/top_sp.mp4");
		}
		if(isPage === "top"){
			$("#main").css({
				"height": $(window).height() + "px"
			});
		}
		if(isPage === "comment"){
			comment.resize();
		}
		if(isPage === "top" || isPage === "comment"){
			plxs = [];
			$(".plx").each(function(i){
				let _p = object(Plx);
				if(!isSP && !isIPad && !isIE11 && !isEdge){
					_p.top 	= Math.floor( $(this).offset().top ) + common.scrollTop;
				}else{
					_p.top 	= Math.floor( $(this).offset().top );
				}
				if(isSmallScreen){
					_p.diff = $(this).attr("data-y")/2;
				}else{
					_p.diff = $(this).attr("data-y");
				}
				plxs.push(_p);
				$(this).css({
					"transform": ""
				})
			});
			if(common.ready){
				if(isSP || isIE11 || isEdge){
					let topD = $(document).scrollTop();
					common.scrollTop = topD;
					let btmD = topD + window.innerHeight;
					common.scrl(topD, btmD);
				}else{
					common.topD 	= scrollbar.offset.y;
					common.scrollTop = scrollbar.offset.y;
					
					let topD = scrollbar.offset.y;
					let btmD = topD + window.innerHeight;
					common.scrl(topD, btmD);
				}
			}
		}
		
		let len = Math.floor( winH/$(".svg").width() )*2;
		let HTML = "";
		setTimeout(function(){
			$(".block_buy_content .svg").remove();
			let link = "";
			if( getLang() === "en" ){
				link = "/en/ticket/";
			}else{
				link = "/ticket/";
			}
			for(var i = 0; i < len; i++){
				HTML += `<div class="svg"><a href="${link}"><svg x="0px" y="0px" viewBox="0 0 287 25" style="enable-background:new 0 0 287 25;" xml:space="preserve"><g><path class="st0" d="M1,11C1,4.5,4.8,0,10.7,0s9.7,4.5,9.7,11s-3.8,11-9.7,11S1,17.5,1,11z M17.8,11c0-5.4-2.8-8.7-7.2-8.7S3.5,5.6,3.5,11s2.8,8.7,7.2,8.7S17.8,16.4,17.8,11z"/><path class="st0" d="M40.6,0.5v21.1h-2.9L31,10.7c-1.4-2.3-3.1-5.1-4.2-7.1c0.1,4.1,0.1,8,0.1,10.9v7.1h-2.4v-21h2.9l6.7,10.9c1.4,2.3,3.1,5.1,4.2,7.1c-0.1-4.1-0.1-8-0.1-10.9V0.5L40.6,0.5L40.6,0.5z"/><path class="st0" d="M58.2,19.3v2.2H45.7v-21h2.4v18.8H58.2z"/><path class="st0" d="M62.6,0.5H65v21.1h-2.4V0.5z"/><path class="st0" d="M85.6,0.5v21.1h-2.9L76,10.7c-1.4-2.3-3.1-5.1-4.2-7.1c0.1,4.1,0.1,8,0.1,10.9v7.1h-2.3v-21h2.9l6.7,10.9c1.4,2.3,3.1,5.1,4.2,7.1c-0.1-4.1-0.1-8-0.1-10.9V0.5L85.6,0.5L85.6,0.5z"/><path class="st0" d="M93.1,2.7v6.7h10.5v2.2H93.1v7.6h11.5v2.3H90.7v-21h14v2.2L93.1,2.7L93.1,2.7z"/><path class="st0" d="M128.6,19.3v2.2h-12.5v-21h2.4v18.8H128.6z"/><path class="st0" d="M132.5,0.5h2.4v21.1h-2.4V0.5z"/><path class="st0" d="M156.3,0.5l-7.5,21.1h-2.7l-7.6-21h2.5l4.7,13.3c0.6,1.8,1.2,3.6,1.7,5.2c0.5-1.7,1.1-3.4,1.7-5.2l4.6-13.3L156.3,0.5L156.3,0.5z"/><path class="st0" d="M162.4,2.7v6.7h10.5v2.2h-10.5v7.6h11.5v2.3H160v-21h14v2.2L162.4,2.7L162.4,2.7z"/><path class="st0" d="M201.2,2.7h-6.9v18.8h-2.5V2.7h-6.9V0.5h16.2V2.7z"/><path class="st0" d="M205.1,0.5h2.4v21.1h-2.4V0.5z"/><path class="st0" d="M211.7,11c0-6.8,3.9-11,9.3-11c4,0,7.6,2.2,8.6,7.2h-2.4c-0.9-3.7-3.5-5-6.2-5c-4.1,0-6.8,3.2-6.8,8.7s2.8,8.7,6.8,8.7c2.9,0,5.8-1.8,6.3-5.9h2.4c-0.5,5.5-4.6,8.1-8.7,8.1C215.6,22,211.7,17.8,211.7,11z"/><path class="st0" d="M241.8,9.1l8.6,12.5h-2.9l-7.4-10.8l-3.8,3.8v7h-2.4v-21h2.4v11l11-11.1h3.1L241.8,9.1z"/><path class="st0" d="M256.5,2.7v6.7H267v2.2h-10.5v7.6H268v2.3h-13.9v-21h14v2.2L256.5,2.7L256.5,2.7z"/><path class="st0" d="M287,2.7h-6.9v18.8h-2.5V2.7h-6.9V0.5H287V2.7z"/></g><rect y="23.5" class="st0" width="287" height="1.5"/></svg></a></div>`;
			}
			$(".block_buy_content").html(HTML);
			$(".svg").each(function(i){
				$(this).css({
					"top": ($(".svg").width()*i) + "px"
				});
			});
			common.nav();
		}, 100);
		
		animations = [];
		$(".a").each(function(){
			let _a 	= object(Animate);
			_a.top 	= Math.floor( $(this).offset().top );
			_a.show = Math.floor( _a.top + window.innerHeight/4 );
			animations.push(_a);
		});
		
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	checkPlx: function(){
		
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic";
		
		if( $(".scroll-content").length > 0 ){
			scrollbar.scrollTo(0, posY, duration,{});
		}else{
			$page.animate({
				scrollTop : posY + "px"
			},{
				"duration" 	: duration,
				"easing" 	: ease
			});
		}
	},
	scrl: (topD, btmD) =>{
		var alen = animations.length;
		
		if(isPage === "top" || isPage === "comment"){
			home.plx(topD,btmD);
		}
		if( topD >= window.innerHeight){
			if( !$html.classList.contains("scrolled") ){
				$html.classList.add("scrolled");
			}
		}else{
			if( $html.classList.contains("scrolled") ){
				$html.classList.remove("scrolled");
			}
		}
		for(var i = 0; i < alen; i++){
			if(btmD >= animations[i].show){
				activeAnimate(i);
			}
		}
		function activeAnimate(i){
			let $a = $(".a").eq(i);
			if( !$a.hasClass("animate") ){
				$a.addClass("animate");
				setTimeout(function(){
					$a.addClass("animated");
				}, 1000);
			}
		}
	},
	showModalPay: function(){
		$html.classList.add("modal_pay");
		setTimeout(function(){
			$html.classList.add("show_modal_pay");
			$("#btn_close_pay").off().on("click", function(){
				common.closeModalPay();
			});
		}, 50);
	},
	closeModalPay: function(){
		$html.classList.remove("show_modal_pay");
		setTimeout(function(){
			$html.classList.remove("modal_pay");
		}, 600);
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});

var ticket = {
	init: function(){
		fvPlayerSample.init("https://d1ke28lwcarijs.cloudfront.net/vodcf6/fm_free_new.smil/playlist.m3u8", "sample");
		$('#live_ticket input[type="checkbox"]').off().on("change", function(){
			let val = $('#live_ticket input[type="checkbox"]').prop("checked");
			if(val){
				$("#live_ticket .wrap_btn").addClass("active");
			}else{
				$("#live_ticket .wrap_btn").removeClass("active");
			}
		});
	}
}