var Plx = {
	top: 0,
	diff: 0
}
var plxs = [];
var home = {
	init: function(){
		if(isSP){
			common.ready = true;
		}else{
			if(isPage === "ticket" || isPage === "online"){
				
			}else{
				if(!isIE11){
					scrollbar = Scrollbar.init( document.querySelector('#wrapper') );
					common.ready = true;
				}else{
					common.ready = true;
				}
			}
			
		}
		common.resize();
		if(isPage === "ticket" || isPage === "online"){
			$(document).off("scroll touchmove").on("scroll touchmove", function(){
				let topD = $(document).scrollTop();
				common.scrollTop = topD;
				let btmD = topD + window.innerHeight;
				common.scrl(topD, btmD);
			});
			common.start();
		}else{
			if(isSP || isIE11 || isEdge){
				$(document).off("scroll touchmove").on("scroll touchmove", function(){
					let topD = $(document).scrollTop();
					common.scrollTop = topD;
					let btmD = topD + window.innerHeight;
					common.scrl(topD, btmD);
				});
				common.start();
			}else{
				scrollbar.addListener(listener);
				function listener(status){
					common.status = status;
					common.topD = status.offset.y;
					common.scrollTop = status.offset.y;
					let topD = status.offset.y;
					let btmD = topD + window.innerHeight;
					common.scrl(topD, btmD);
				}
				common.start();
			}
		}
		if(isPage === "top"){
			let hash = location.hash;
			if(hash != ""){
				hash = hash.replace("#/", "");
				let posY = $("#" + hash).offset().top;
				common.scrollToPosition(posY, 1000);
			}
			
		}
	},
	plx: function(topD, btmD){
		let plen = plxs.length;
		for(var i = 0; i < plen; i++){
			if(btmD >= plxs[i].top){
				let p = plxs[i];
				let per = 1 - (btmD - p.top)/(window.innerHeight/2);
				let diff = p.diff;
				
				//console.log(j, diff);
				let _y = diff*per + diff/2;
				
				$(".plx").eq(i).css({
					"transform": `translate3d(0, ${_y}px, 0)`
				});
			}else{
				$(".plx").eq(i).css({
					"transform": ``
				});
			}
		}
	}
}