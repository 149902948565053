var goods = {
	init: function(){
		let jsonURL = "/assets/json/goods.json";
		let param = {};
		load.getJson(jsonURL, param).done(function(json){
			console.log(json);
			goods.makeHTML("goods_original", json.original);
			goods.makeHTML("goods_rzm", json.rzm);
			goods.makeHTML("goods_ep", json.elevenplay);
		});
	},
	makeHTML: function(id, data){
		let HTML = "";
		for(var i = 0; i < data.length; i++){
			let d = data[i];
			let size = "";
			if(d.size != ""){
				size = `<span class="size">${d.size}</span>`
			}
			let buyBtn = "";
			let cls = "";
			if(d.limited === 1){
				cls = "limited";
			}else{
				buyBtn = `<a class="btn_buy" href="${d.link}" title="BUY ONLINE" target="_blank" rel="noopener"><span>BUY ONLINE</span></a>`;
			}
			//${buyBtn}
			HTML += `<li class="${cls}">
						<div class="img"><figure style="background-image: url(/assets/images/goods/${d.image});"></figure></div>
						<div class="ttl">
							<div class="cols">
								<h4><span>${d.name}</span>${size}</h4>
								<p class="price">${d.price}</p>
							</div>
						</div>
					</li>`;
		}
		$("#" + id).find("ul").html(HTML);
	}
}