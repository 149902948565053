const 	ua 		= window.navigator.userAgent.toLowerCase(),
		appver 	= window.navigator.appVersion.toLowerCase();
let isLegacy 	= appver.indexOf("msie 8.0") > -1 || appver.indexOf("msie 9.0") > -1 || appver.indexOf("msie 10.0") > -1,
	isIE 		= ua.indexOf("msie") !== -1 || navigator.userAgent.match(/Trident/),
	isIE11 		= navigator.userAgent.toLowerCase().indexOf('trident/7') > -1,
	isAndroid 	= navigator.userAgent.match(/Android/i),
	isIPhone 	= navigator.userAgent.match(/iPhone/i),
	isSP 		= navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i),
	isIPad 		= navigator.userAgent.match(/iPad/i),
	isFF 		= ua.indexOf("firefox")!== -1,
	isEdge 		= ua.indexOf("applewebkit") > -1 && ua.indexOf("edge") > -1,
	isNewEdge 	= ua.indexOf("applewebkit") > -1 && ua.indexOf("") > -1,
	isMobile	= true,
	isSmallScreen = false,
	isPC = true;
var $html = document.documentElement;
if(isAndroid || isIPhone || isIPad){
	isPC = false;
	isSP = true;
}else{
	isPC = true;
}
if(isIPad){
	$html.classList.add("iPad");
}

if (navigator.platform == "MacIntel" &&　navigator.userAgent.indexOf("Safari") != -1 &&　navigator.userAgent.indexOf("Chrome") == -1) {
	if (navigator.standalone !== undefined) {
		$html.classList.add("iPad");
		isIPad = true;
	}
}


let domain = location.host;
let apiURL = "";
if( domain === "imac-pro.local:5757" || domain === "localhost:5757" || domain === "stg.border.dance"){
	apiURL = "https://stg-api.border.dance";
}else{
	apiURL = "https://api.border.dance";
}

if(isIPhone){
	$html.classList.add("iphone");
	if(window.devicePixelRatio === 3 && window.screen.width === 390 && window.screen.height === 844 ||
		window.devicePixelRatio === 3 && window.screen.width === 414 && window.screen.height === 896 ||
		window.devicePixelRatio === 3 && window.screen.width === 428 && window.screen.height === 926 ||
		window.devicePixelRatio === 3 && window.screen.width === 375 && window.screen.height === 812 || 
		window.devicePixelRatio === 3 && window.screen.width === 414 && window.screen.height === 896 || 
		window.devicePixelRatio === 2 && window.screen.width === 414 && window.screen.height === 896) {
		
	}else{
		$html.classList.add("iphone_legacy");
	}
}else if(isAndroid){
	$html.classList.add("android");
}
//win or mac
//win or mac
if( navigator.userAgent.indexOf("Win") !== -1 ){
	let ary = ["os_windows"];
	if(isFF){
		ary.push("firefox");
	}else{
		if(isIE){
			if(isIE11){
				ary.push("ie11");
			}
		}else if(window.navigator.userAgent.indexOf('Edge') != -1 || window.navigator.userAgent.indexOf('Edg') != -1){
			if(isEdge){
				ary.push("edge");
			}else{
				ary.push("chronium_edge");
			}
		}else{
			if( (ua.indexOf('chrome') !== -1) && (ua.indexOf('edge') === -1)  && (ua.indexOf('opr') === -1) ){
				ary.push("win_chrome");
			}
		}
	}
	addClassToBody(ary);
}
if(navigator.userAgent.indexOf("Mac") !== -1 ){
	let ary = ["os_mac"];
	if(isFF){
		ary.push("firefox");
	}else{
		if (ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1){
			ary.push("safari");
		}else{
			ary = ["os_mac"];
		}
	}
	addClassToBody(ary);
}
function addClassToBody(ary){
	for(let i = 0; i < ary.length; i++){
		$html.classList.add(ary[i]);
	}
}
var mouse = [];
function object(o) {
	var f = object.f, i, len, n, prop;
	f.prototype = o;
	n = new f;
	for (i=1, len=arguments.length; i<len; ++i){
		for (prop in arguments[i]){
			n[prop] = arguments[i][prop];
		}
	}
	return n;
}
object.f = function(){};

var observer;
var ioArry = [
	{
		"target": ".a",
		"margin": '0px 0px 0px 0px',
		"threshold": [0, 1],
		"change": function(entries){
			//ヘッダーの固定、固定解除-----------------------------------
			let $header = $("header#global_header");
			entries.forEach( function(entry, index){
				let idx = $(".a").index(entry);
				var $block = $(".a").eq(idx);
				observer.unobserve(element);
				if(!$block.hasClass("animate")){
					$block.addClass("animate");
					setTimeout(function(){
						$block.addClass("animated");
					},1000);
				}
			});
		}
	}
];

var getLang = function(){
	let url = location.pathname;
	let thisLang;
	let arry = url.split("/");
	if(arry[1] === "en"){
		thisLang = "en";
	}else{
		thisLang = "jp";
	}
	return thisLang;
}

var load = {
	getJson: function (url, params) {
		var defer = $.Deferred();
		var url = url;
		$.ajax({
			type: "GET",
			dataType: "json",
			url: url,
			data: params,
			success: defer.resolve,
			error: defer.reject,
		});
		return defer.promise();
	},
	postJson: function(url, params) {
		var defer 	= $.Deferred();
		$.ajax({
			type: "POST",
			dataType: 'json',
			url: url,
			data: params,
			success	: defer.resolve,
			error: function(XMLHttpRequest, textStatus, errorThrown){
				if(XMLHttpRequest.responseJSON.message === "ng_word"){
					common.showError();
				}
				defer.reject
			}
		});
		return defer.promise();
	}
};