var me = {
	token	: "",
	ticketID: "",
	serial	: ""
}
let txts = {
	before 	: "こちらのページでborder 2021公演をオンライン配信致します。<br />ご購入時に指定された日のAM 11:50以降に再度アクセスしてください。",
	after 	: "配信は終了致しました。<br />ご視聴ありがとうございました。",
	day1end : "こちらのページでborder 2021公演をオンライン配信致します。<br />2月14日のチケットをご購入された方はの2月14日AM 11:50以降に再度アクセスしてください。<br />※2月13日の配信は終了致しました。",
	endday	: "ご購入いただいたシリアルコードで視聴できる配信は終了致しました。<br />ご視聴ありがとうございました。",
	notaday	: "こちらのシリアルコードはオンラインチケット購入時に指定した日付のみ有効です。<br />ご購入時に指定された日（2月13日もしくは14日）に再度ログインしてください。"
}
let txtsen = {
	before	: "border 2021 will be streamed at this page.<br />Please access again after 11:50am (JST) of the date specified on your ticket.<br />If you have not yet purchased online streaming tickets, please click here.",
	after	: "&quot;border 2021&quot; has ended.<br />Thank you for watching.",
	day1end : "The streaming of &quot;border 2021&quot; will be available online at this page.<br />If you have purchased tickets for February 14, please access this page again after 11:50 AM on February 14.<br />*** The streaming of Feburary 13 is finished.",
	endday	: "Your ticket became invalid for the streaming already ended.<br />Thank you for watching.",
	notaday	: "The code you entered is incorrect.<br />Please enter the &quot;Seller order ID&quot; in the email that was sent to you when you purchased your ticket."
}
var online = {
	before: "",
	end: "",
	day1: "",
	day2: "",
	currentDate: "",
	init: function(){
		//console.log("online");
		online.nav();
		/*
		let before 	= new Date(2021, 1, 13, 11, 50, 0);
		let middle 	= new Date(2021, 1, 13, 20, 0, 0);
		let endstart = new Date(2021, 1, 14, 11, 50, 0);
		let end 		= new Date(2021, 1, 14, 20, 0, 0);
		online.before 	= before.getTime();
		online.end 		= end.getTime();
		let day1end 	= middle.getTime();
		let day2start 	= endstart.getTime();
		*/
		let timeURL = "/assets/json/mode.json";
		load.getJson(timeURL,{}).done(function(json){
			console.log(json);
			let mode = json.mode;
			//let now = new Date(json.jp);
			/*
			console.log(json);
			let now = moment(new Date(json.now*1000)).tz('Asia/Tokyo');
			console.log(now.valueOf());
			
			let serverDate = new Date( now );
			console.log(serverDate);
			//var serverDate = new Date(xhr.getResponseHeader('Date'));
			//serverDate = online.currentDate;
			let current = now.valueOf();
			
			console.log(current, online.before)
			*/
			let lang = getLang();
			if(mode === 1){
				//console.log("開始前");
				if(lang === "en"){
					$(".date_info p").html(txtsen.before);
				}else{
					$(".date_info p").html(txts.before);
				}
				
				$(".date_info").addClass("active");
				$("#input_serial").prop("disabled", true);
				$(".block_login p.lead").remove();
				$(".block_login dl").remove();
			}
			//開演後
			if(mode === 3){
				//1日目終了
				if(lang === "en"){
					$(".date_info p").html(txtsen.day1end);
				}else{
					$(".date_info p").html(txts.day1end);
				}
				
				$(".date_info").addClass("active");
				$("#input_serial").prop("disabled", true);
				$(".block_login p.lead").remove();
				$(".block_login dl").remove();
			}
			if(mode === 5){
				if(lang === "en"){
					$(".date_info p").html(txtsen.after);
				}else{
					$(".date_info p").html(txts.after);
				}
				
				$(".date_info").addClass("active");
				$("#input_serial").prop("disabled", true);
				$(".block_login p.lead").remove();
				$(".block_login dl").remove();
				$("p.note").remove();
			}
		});
		//console.log(online.before, online.end);
	},
	nav: function(){
		$(".open_modal").off().on("click", function(){
			if( !$html.classList.contains("modal") ){
				online.openModal();
			}else{
				online.closeModal();
			}
		});
		$("#debug a").off().on("click", function(){
			let y = parseInt( $("#y").val() );
			let m = parseInt( $("#m").val() );
			let d = parseInt( $("#d").val() );
			let h = parseInt( $("#h").val() );
			let min = parseInt( $("#min").val() );
			let s = parseInt( $("#s").val() );
			let now = y + "/" + m + "/" + d + " " + h + ":" + min + ":" + s;
			let current = new Date( now );
			online.currentDate = current;
			online.init();
		});
		
		$("#input_serial").off().on("change", function(){
			online.checkInput();
		});
		$("a.btn_submit").off().on("click", function(){
			me.serial = $("#input_serial").val();
			online.checkSerial(me.serial);
		});
	},
	openModal: function(){
		$html.classList.add("modal");
		setTimeout(function(){
			$html.classList.add("show_modal");
			online.nav();
			$("#btn_close_modal").off().on("click", function(){
				online.closeModal();
			});
		}, 50);
	},
	closeModal: function(){
		$html.classList.remove("show_modal");
		setTimeout(function(){
			$html.classList.remove("modal");
		}, 600);
	},
	checkInput: function(){
		let $btnEnter = $("dd.submit");
		let arry = [0];
		//let idVal = $("#input_id").val();
		let serialVal = $("#input_serial").val();
		
		if(serialVal != ""){
			arry[0] = 1;
		}else{
			arry[0] = 0;
		}
		if( arry.indexOf(0) > -1){
			if( $btnEnter.hasClass("active") ){
				$btnEnter.removeClass("active");
			}
		}else{
			if( !$btnEnter.hasClass("active") ){
				$btnEnter.addClass("active");
			}
		}
	},
	purchase: function(orderID, ticketID){
		console.log(orderID);
		let url = apiURL + "/purchases.json";
		let params = {
			"ticket_id": ticketID,
			"amazon_order_reference_id": orderID
		}
		load.postJson(url, params).done(function(json){
			console.log(json);
			let ticketID = $("#select_date option:selected").val();
			let date 	= $("#select_date option:selected").text();
			//console.log(date);
			$("#pay_content dd.bought_date").html(date);
			$("#pay_content").addClass("complete");
			$("#pay_content h6#serial").html(json.serial_code);
			setTimeout(function(){
				$("#modal_pay a.btn_close").off("click").on("click", function(){
					common.closeModalPay();
				});
			}, 100);
		});
	},
	checkSerial: function(serial){
		let url = apiURL + "/purchases/verify.json";
		let day1 	= new Date(2021,1,13,0,0,0);
		let day2 	= new Date(2021,1,13,20,0,0);
		let day1start = day1.getTime();
		let day2start = day2.getTime();
		let params = {
			"serial_code": serial
		}
		
		$.ajax({
			type: "POST",
			dataType: 'json',
			url: url,
			data: params,
			success	: function(json){
				me.token = json.access_token;
				me.ticketID = json.ticket_id;
				console.log(json);
				let lang = getLang();
				let timeURL = "/assets/json/mode.json";
				load.getJson(timeURL,{}).done(function(date){
					let mode = date.mode;
					let serverDate = new Date( date.now*1000 );
					
					let current = serverDate.getTime();
					//console.log(serverDate, current);
					if(json.ticket_id === 1){
						//1日目
						if(mode === 2){
							onlineMovie.init();
						}else if(mode === 3 || mode === 4){
							//昨日のチケットです。
							if(lang === "en"){
								$(".date_info p").html(txtsen.endday);
							}else{
								$(".date_info p").html(txts.endday);
							}
							
							$(".date_info").addClass("active");
							//$("#input_serial").prop("disabled", true);
						}
					}else{
						//2日目
						if( mode === 2 || mode === 3){
							if(lang === "en"){
								$(".date_info p").html(txtsen.notaday);
							}else{
								$(".date_info p").html(txts.notaday);
							}
							$(".date_info").addClass("active");
							//$("#input_serial").prop("disabled", true);
						}else if( mode === 4 ){
							onlineMovie.init();
						}
					}
				});
			},
			error: function(XMLHttpRequest, textStatus, errorThrown){
				$(".error").addClass("hasError");
			}
		});
	}
}